




































































import {Component, Vue} from "vue-property-decorator";
import {VekaletListResponseProvider} from "@/services/VekaletService";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {TableHeader} from "@/interface/TableHeader";
import MuvekkilList from "@/components/vekalet/MuvekkilList.vue";
import FormDialogButton from "@/components/common-inputs/FormDialogButton.vue";
import NoterIslemForm from "@/components/comps/forms/NoterIslemForm.vue";
import VekaletForm from "@/components/vekalet/VekaletForm.vue";
import DeleteButton from "@/components/common-inputs/DeleteButton.vue";

@Component({
    components: {DeleteButton, VekaletForm, NoterIslemForm, FormDialogButton, MuvekkilList}
})
export default class VekaletListe extends Vue {
    search: string = "";
    items: VekaletEntity[] | null = null;
    headers: TableHeader[] = [
        {
            text: "Vekalet Adı",
            align: "start",
            sortable: false,
            value: "ad"
        },
        {text: "Müvekiller", value: 'muvekkiller'},
        {text: "İl", value: "noterIslem.noter.il.isim"},
        {text: "Noter", value: "noterIslem.noter.ad"},
        {text: "Vekaletname Tarihi", value: "noterIslem.tarih"},
        {text: "İşlemler", value: "actions", width: 110, sortable: false}
    ];

    vekalet = {};

    mounted() {
        window.setTimeout(() => {
            this.items = VekaletListResponseProvider().data;
            console.log("vekaletler:",this.items);
        }, 500);
    }

    add() {
        this.$router.push("/vekalet/ekle");
    }

    detay(item: VekaletEntity) {
        this.$router.push("/vekalet/" + item.id?.toString() + "/detay");
    }
}
