var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"color":"light-blue darken-4","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Vekalet Listesi")]),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":"","label":"Ara","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('form-dialog-button',{attrs:{"title":"Vekalet Bilgileri","icon":"mdi-plus","dark":""},on:{"success":function (o) { this$1.items.push(o) }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formData = ref.formData;
var onInput = ref.onInput;
return [_c('vekalet-form',{on:{"input":onInput},model:{value:(formData),callback:function ($$v) {formData=$$v},expression:"formData"}})]}}])})],1),(_vm.items!=null)?_c('v-data-table',{staticClass:"table table-striped",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.ad",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"seedetails",on:{"click":function($event){return _vm.detay(item)}}},[_vm._v(_vm._s(item.ad))])]}},{key:"item.muvekkiller",fn:function(ref){
var item = ref.item;
return [(item.muvekkiller.length > 4)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ul',_vm._g({},on),[_vm._l((3),function(index){return _c('li',[_vm._v(" "+_vm._s(item.muvekkiller[index-1].kisiKurum.ad)+" "+_vm._s(item.muvekkiller[index-1].kisiKurum.soyad)+" ")])}),_c('li',[_vm._v("...")])],2)]}}],null,true)},[_c('ul',_vm._l((item.muvekkiller),function(muvekkil){return _c('li',[_vm._v(_vm._s(muvekkil.kisiKurum.ad)+" "+_vm._s(muvekkil.kisiKurum.soyad))])}),0)])]:_c('ul',_vm._l((item.muvekkiller),function(muvekkil){return _c('li',[_vm._v(_vm._s(muvekkil.kisiKurum.ad)+" "+_vm._s(muvekkil.kisiKurum.soyad))])}),0)]}},{key:"item.noterIslem.tarih",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$helper.tarihStr(item.noterIslem.tarih))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"light-blue darken-4","icon":""},on:{"click":function($event){return _vm.detay(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold-box")])],1),_c('delete-button')]}}],null,false,577092223)}):_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }